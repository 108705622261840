<template>
    <div>
        <transition name="slide-up">
            <div class="content-container content-half" v-if="isActive">
                <div class="flex-column">
                    <div class="d-flex">
                        <!--<div class="question-number">3-</div>-->
                        <div class="flex-column padding-div w-100">
                            <div class="fs-32px fw-700 color-dark mb-5">
                                Dados necessários para a inscrição
                            </div>

                            <div class="content-contents">
                                <v-form ref="form">

                                    <div class="mb-4 mt-5">
                                        <div class="fs-24px fw-500 color-dark">
                                            Nome
                                        </div>
                                        <div class="single-input">
                                            <v-text-field required :rules="[rules.required, rules.onlyalpha, rules.surnamerequired]" class="input-caps" placeholder="Digite sua resposta aqui" v-model="nome" />
                                        </div>
                                    </div>
                                    <div class="mb-4 mt-5">
                                        <div class="fs-24px fw-500 color-dark">
                                            E-mail
                                        </div>
                                        <div class="single-input">
                                            <v-text-field required :rules="[rules.required, rules.email]" placeholder="Digite sua resposta aqui" v-model="email" />
                                        </div>
                                    </div>
                                    <div class="mb-4">
                                        <div class="d-flex justify-content-between">
                                            <div class="w-100 padding-right">
                                                <div class="fs-24px fw-500 color-dark">
                                                    CPF
                                                </div>
                                                <div class="single-input">
                                                    <v-text-field-simplemask required :rules="[rules.required, rules.validcpf]" v-model="cpf"
                                                                             v-bind:properties="{
        prefix: '',
        suffix: '',
        readonly: false,
        disabled: false,
        outlined: false,
        clearable: true,
        placeholder: 'Digite sua resposta aqui',
      }"
                                                                             v-bind:options="{
        inputMask: '###.###.###-##',
        outputMask: '###.###.###-##',
        empty: null,
        applyAfter: false,
        alphanumeric: false,
        lowerCase: false,
      }" />
                                                </div>
                                            </div>
                                            <div class="w-100 padding-left">
                                                <div class="fs-24px fw-500 color-dark">Data de nascimento:</div>
                                                <div class="single-input">
                                                    <v-text-field-simplemask required :rules="[rules.required, rules.validdate]" v-model="dataNascimento"
                                                                             v-bind:properties="{
        prefix: '',
        suffix: '',
        readonly: false,
        disabled: false,
        outlined: false,
        clearable: true,
        placeholder: 'DD/MM/YYYY',
      }"
                                                                             v-bind:options="{
        inputMask: '##/##/####',
        outputMask: '##/##/####',
        empty: null,
        applyAfter: false,
        alphanumeric: false,
        lowerCase: false,
      }" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </v-form>
                            </div>
                            <div class="content-buttons">
                                <div class="d-flex justify-content-end">
                                    <button v-on:click="next" class="button-primary-bg-primary action-button">
                                        Avançar
                                        <v-icon right>east</v-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="button" class="btn fill-after hide" v-show="false" data-bs-toggle="modal" ref="popParticipanteBtn" data-bs-target="#modalParticipante">
                    popParticipanteBtn
                </button>
            </div>
        </transition>

        <!-- Modal 3-->
        <div class="modal fade valia-modal" id="modalParticipante" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalmodalParticipanteLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" style="z-index:99 !important;">
                <div class="modal-content">

                    <div class="modal-body">
                        <div class="fs-24px color-primary fw-700 text-center">Atenção!</div>
                        <div class="mt-3">
                            <div class="fs-18px">
                                Você é um participante Valia, deseja realizar sua adesão pelo portal do participante? <small>(será necesário login e senha)</small>
                            </div>
                        </div>
                        <div class="mt-5 d-flex flex-column align-items-center">
                            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                                <button class="button-primary-bg-primary w-100 modal-button" @click="gotoportal()" data-bs-dismiss="modal">
                                    Desejo continuar pela área logada
                                </button>
                                <button type="button" class="btn fill-after-w-100 mt-3 w-100 modal-button" @click="goahead()" data-bs-dismiss="modal">
                                    Continuar a adesão
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import 'bootstrap';
    import moment from 'moment';

    import SimpleMask from "../../components/SimpleMask.vue";
    import { mapState } from "vuex";
    import Enumerable from "linq";
    import PlanoFamiliaAPI from '../../services/PlanoFamiliaAPI';
    import CorporativoAPI from '../../services/CorporativoAPI';
    import SeguridadeAPI from '../../services/SeguridadeAPI';
    import Common from "../../services/common/Common";
    import constants from '../../constants';
    
    export default {
        name: 's25-inscricao',
        props: ['pessoa', 'visible', 'fluxo', 'responsavelpelomenor'],
        components: {
            "v-text-field-simplemask": SimpleMask,
        },
        computed: {
            ...mapState(["userIP"]),
            ...mapState(["tiposRepresentacao"]),
        },
        data: () => ({
            arealogada_url: constants.URL_PORTAL_AREA_LOGADA,
            isActive: true,
            loading: false,
            selection: false,
            step: 1,
            nome: null,
            cpf: null,
            tipoRepresentacao: null,
            dataNascimento: null,
            telefone: null,
            email: null,
            hasErrors: false,
            rules: {
            },
            pessoa: {}
        }),
        watch: {
            visible: function () {
                console.log("visible");
                this.toggle();
            },
        },
        methods: {
            toggle() {
                console.log("toggle");
                this.isActive = !this.isActive;

                if (this.isActive) {
                    //this.habilitaOpcoes();
                }
            },
            habilitaOpcoes() {

                if (this.pessoa === undefined) {
                    this.pessoa = {};
                }
                if (this.pessoa.telefone === undefined) {
                    this.pessoa.telefone = {};
                }

            },
            next: async function (event) {
                event.preventDefault();

                this.rules = {
                    required: value => !!value || 'Campo obrigatório.',
                    onlyalpha: value => {
                        const pattern = /^[A-Za-zà-úá-úâ-ûä-üã-õçñÀ-ÙÁ-ÚÂ-ÛÄ-ÜÃ-ÕÇÑ ]+$/
                        return pattern.test(value) || 'Preencha somente com letras.'
                    },
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'E-mail inválido.'
                    },
                    validdate: v => {
                        if (v == null) return true;

                        var s = v.split('/');
                        var d = new Date(+s[2], s[1] - 1, +s[0]);
                        var ok = false;
                        if (Object.prototype.toString.call(d) === "[object Date]") {
                            if (!isNaN(d.getTime()) && d.getDate() == s[0] &&
                                d.getMonth() == (s[1] - 1)) {
                                ok = true;
                                //return true;
                            }
                        }
                        if (ok && (parseInt(+s[2]) < 1000 || d.getFullYear() < 1000))
                            ok = false;

                        if (!ok)
                            return "Data inválida";

                    },
                    surnamerequired: value => {
                        const expressaoRegular = /^\w+\s+\w+(\s+\w+)*$/;
                        return expressaoRegular.test(value) || 'Preencha seu nome e sobrenome.'
                    },
                    celular: value => {
                        if (value !== undefined && value !== null && value != "") {
                            value = value.replace("-", "");
                            value = value.replace("(", "");
                            value = value.replace(")", "");
                            value = value.replace(" ", "");
                            if (value.length < 11 || isNaN(value)) {
                                return "Número inválido";
                            }
                        }
                        else {
                            return "Número inválido";
                        }
                    },
                    validcpf: ii => {
                        if (ii == null) return true;

                        var c = ii.replace(/[^\d]/g, "");
                        if (c.length != 11)
                            return "CPF inválido";

                        if (c == "00000000000" ||
                            c == "11111111111" ||
                            c == "22222222222" ||
                            c == "33333333333" ||
                            c == "44444444444" ||
                            c == "55555555555" ||
                            c == "66666666666" ||
                            c == "77777777777" ||
                            c == "88888888888" ||
                            c == "99999999999")
                            return "CPF inválido";

                        var r;
                        var s = 0;

                        for (i = 1; i <= 9; i++)
                            s = s + parseInt(c[i - 1]) * (11 - i);

                        r = (s * 10) % 11;

                        if ((r == 10) || (r == 11))
                            r = 0;

                        if (r != parseInt(c[9]))
                            return "CPF inválido";

                        s = 0;

                        for (var i = 1; i <= 10; i++)
                            s = s + parseInt(c[i - 1]) * (12 - i);

                        r = (s * 10) % 11;

                        if ((r == 10) || (r == 11))
                            r = 0;

                        if (r != parseInt(c[10]))
                            return "CPF inválido";

                        return true;
                    },
                };


                this.$nextTick(async () => {
                    if (this.$refs.form.validate()) {


                        //colocar as regras de check

                        var cpfLimpo = this.cpf.replaceAll(".", "").replaceAll("-", "");

                        this.pessoa = {};
                        this.pessoa["participante-valia"] = {};
                        this.pessoa['perfil-plano'] = {};
                        this.pessoa['perfil-plano']["forma-cobranca"] = "b";
                        this.pessoa["perfil-plano"]["tipo-folha-pagamento"] = null;
                        this.pessoa.detalhesDaAdesao = {};
                        this.pessoa.detalhesDaAdesao.status = "Em Andamento"
                        this.pessoa.detalhesDaAdesao.publicoExterno = true;
                        this.pessoa.nome = this.nome.capitalize();
                        this.pessoa.cpf = cpfLimpo;
                        this.pessoa.email = this.email;
                        this.pessoa["dataNascimento"] = this.dataNascimento;

                        this.pessoa.detalhesDaAdesao.etapas = [];
                        this.pessoa.detalhesDaAdesao.etapas.push(Common.createEtapa("Etapa 1", this.pessoa.nome, this.pessoa.cpf, this.userIP));

                        var v = this.telefone;
                        if (v !== undefined && v !== null && v != "") {
                            v = v.replace("-", "");
                            v = v.replace(" ", "");
                            this.pessoa.telefone = {};
                            this.pessoa.telefone.ddi = "55";
                            this.pessoa.telefone.ddd = v.substring(0, 2);
                            this.pessoa.telefone.numero = v.substring(2, 11);
                            this.pessoa.telefone.tipo = "1";
                        }

                        this.hasErrors = false;

                        var date = moment(this.pessoa.dataNascimento, "DD/MM/YYYY");

                        var age = this.calculate_age(date.toDate());

                        if (age >= 18) {
                            this.fluxo = "aberturamaior18";
                            this.pessoa["menor-idade"] = "nao";
                        }
                        else {
                            this.fluxo = "aberturamenor18";
                            this.pessoa["menor-idade"] = "sim";
                        }

                        
                        var p = null;
                        var breakProcess = false;


                        if (!breakProcess) {
                            await PlanoFamiliaAPI.getPessoasAll(cpfLimpo, null)
                                .then(response => {
                                    if (response.data != null && response.data.Documents != null) {
                                        p = response.data.Documents;
                                        var existeAdesao = Enumerable.From(p)
                                            .Where("$.detalhesDaAdesao.status == 'PendenteAnalise' || $.status == 'Validado' ||$.status == 'Enviado' ").FirstOrDefault();
                                        if (existeAdesao) {
                                            breakProcess = true;
                                            Common.notifyError("Erro", "Já existe uma inscrição para este CPF em análise pela Valia. Aguarde a finalização deste processo para iniciar uma nova adesão. Para saber a situação desta adesão, acesse novamente o portal informando o seu CPF e o Protocolo que reenviamos para o e-mail cadastrado.");
                                            return false;
                                        }
                                    }

                                })
                                .catch(e => {
                                    console.log(e);
                                    breakProcess = true;
                                    Common.notifyError(null, null);
                                    return false;
                                });
                        }

                        var token = "";
                        if (!breakProcess) {
                            await SeguridadeAPI.postSessoes().then(response => {
                                if (response.data !== undefined && response.data.Resultado !== undefined) {
                                    token = response.data.Resultado.Token;
                                }
                                else {
                                    breakProcess = true;
                                    Common.notifyError(null, null);
                                    return false;
                                }
                            })
                                .catch(e => {
                                    console.log(e);
                                    breakProcess = true;
                                    Common.notifyError(null, null);
                                    return false;
                                });
                        }

                        if (!breakProcess) {
                            await CorporativoAPI.getPessoasUnificado(cpfLimpo, token).then(response => {
                                if (response.data != null && Array.isArray(response.data) && response.data.length > 0) {

                                    //if (response.data[0].Erro !== undefined)
                                    //    console.log("nao dispara pop");

                                    if (response.data[0].Erro === undefined) {
                                        this.$refs.popParticipanteBtn.click()
                                        breakProcess = true;
                                        return false;
                                    }
                                }
                            })
                                .catch(e => {
                                    console.log(e);
                                    breakProcess = true;
                                    Common.notifyError(null, null);
                                    return false;
                                });
                        }

                        


                        if (!breakProcess) {
                            await this.goahead()
                        }
                    }
                })
            },
            gotoportal: function () {
                window.location.href = this.arealogada_url;
            },
            goahead: async function () {
                //var id = "";
                var protocolo = "";
                var cpfLimpo = this.cpf.replaceAll(".", "").replaceAll("-", "");
                await PlanoFamiliaAPI.postPessoas(this.pessoa).then(response => {
                    if (response.data != null) {
                        //id = response.data.id;
                        protocolo = response.data.detalhesDaAdesao.protocolo;
                    }
                })
                    .catch(e => {
                        console.log(e);
                        Common.notifyError(null, null);
                        return false;
                    });

                this.pessoa = await Common.loadPessoa(cpfLimpo, protocolo);
                
                var ret = {
                    pessoa: this.pessoa,
                    fluxo: this.fluxo
                }

                this.$emit('next', ret);
            },
            back: function () {
                this.$emit('back');
            },
            calculate_age: function (dob) {
                var diff_ms = Date.now() - dob.getTime();
                var age_dt = new Date(diff_ms);

                return Math.abs(age_dt.getUTCFullYear() - 1970);
            }
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

