<template>
    <transition name="slide-up">
        <div class="content-container content-half" v-if="isActive">
            <div class="flex-column">
                <div class="d-flex">
                    <!--<div class="question-number">3-</div>-->
                    <div class="flex-column padding-div w-100">
                        <div class="fs-32px fw-700 color-dark mb-5">
                            Informe os dados do(a) representante legal
                        </div>

                        <div class="content-contents">
                            <v-form ref="form">

                                <div class="mb-4 mt-5">
                                    <div class="fs-24px fw-500 color-dark">
                                        Qual o nome do(a) representante?
                                    </div>
                                    <div class="single-input">
                                        <v-text-field required :rules="[rules.required, rules.onlyalpha]" class="input-caps" placeholder="Digite sua resposta aqui" v-model="nome" />
                                    </div>
                                </div>

                                <div class="mb-4">
                                    <div class="d-flex justify-content-between">
                                        <div class="w-100 padding-right">
                                            <div class="fs-24px fw-500 color-dark">
                                                O e-mail dele(a):
                                            </div>
                                            <div class="single-input">
                                                <v-text-field required :rules="[rules.required, rules.email]" placeholder="Digite sua resposta aqui" v-model="email" />
                                            </div>
                                        </div>
                                        <div class="w-100 padding-left">
                                            <div class="fs-24px fw-500 color-dark">O telefone dele(a):</div>
                                            <div class="single-input">
                                                <v-text-field-simplemask v-model="telefone" class="input-caps" required :rules="[rules.required, rules.celular]"
                                                                         v-bind:properties="{
        prefix: '',
        suffix: '',
        readonly: false,
        disabled: false,
        outlined: false,
        clearable: true,
        placeholder: '',
      }"
                                                                         v-bind:options="{
        inputMask: '(##) #####-####',
        outputMask: '###########',
        empty: null,
        applyAfter: false,
        alphanumeric: false,
        lowerCase: false,
      }" @keyup.native.enter="send" v-on:keyup.enter="send"
                                                                         v-bind:focus="focus"
                                                                         v-on:focus="focus = false" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-4">
                                    <div class="fs-24px fw-500 color-dark">Data de nascimento dele(a):</div>
                                    <div class="single-input">
                                        <v-text-field-simplemask required :rules="[rules.required, rules.validdate]" v-model="dataNascimento"
                                                                 v-bind:properties="{
        prefix: '',
        suffix: '',
        readonly: false,
        disabled: false,
        outlined: false,
        clearable: true,
        placeholder: 'DD/MM/YYYY',
      }"
                                                                 v-bind:options="{
        inputMask: '##/##/####',
        outputMask: '##/##/####',
        empty: null,
        applyAfter: false,
        alphanumeric: false,
        lowerCase: false,
      }" />
                                    </div>
                                </div>
                                <div class="mb-4">
                                    <div class="fs-24px fw-500 color-dark">
                                        Qual o parentesco do(a) representante?
                                    </div>
                                    <div class="n-form-select">
                                        <v-select required :rules="[rules.required]" class="input-caps" placeholder="Selecione o tipo de parentesco" v-bind:items="tiposRepresentacao"
                                                  v-model="tipoRepresentacao" item-value="TPRTPREPRESENTANCAO" item-text="TPRDESTPREPRESENTACAO"></v-select>
                                    </div>
                                </div>
                                <div class="mb-4">
                                    <div class="fs-24px fw-500 color-dark">Digite o número do CPF dele(a):</div>
                                    <div class="single-input">
                                        <v-text-field-simplemask required :rules="[rules.required, rules.validcpf]" v-model="cpf"
                                                                 v-bind:properties="{
        prefix: '',
        suffix: '',
        readonly: false,
        disabled: false,
        outlined: false,
        clearable: true,
        placeholder: '',
      }"
                                                                 v-bind:options="{
        inputMask: '###.###.###-##',
        outputMask: '###.###.###-##',
        empty: null,
        applyAfter: false,
        alphanumeric: false,
        lowerCase: false,
      }" />
                                    </div>
                                </div>
                            </v-form>
                        </div>
                        <div class="content-buttons">
                            <div class="d-flex justify-content-end">
                                <button v-on:click="next" class="button-primary-bg-primary action-button">
                                    Avançar
                                    <v-icon right>east</v-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import 'bootstrap';

    import SimpleMask from "../../components/SimpleMask.vue";
    import { mapState } from "vuex";
    export default {
        name: 's01-representante-2',
        props: ['pessoa', 'visible', 'fluxo', 'responsavelpelomenor'],
        components: {
            "v-text-field-simplemask": SimpleMask,
        },
        computed: {
            ...mapState(["tiposRepresentacao"]),
        },
        data: () => ({
            isActive: false,
            loading: false,
            selection: false,
            step: 1,
            nome: null,
            cpf: null,
            tipoRepresentacao: null,
            dataNascimento: null,
            telefone: null,
            email: null,
            hasErrors: false,
            rules: {
            },
        }),
        watch: {
            visible: function () {
                this.toggle();
            },
        },
        methods: {
            toggle() {
                this.isActive = !this.isActive;

                if (this.isActive) {
                    this.habilitaOpcoes();
                }
            },
            habilitaOpcoes() {
                
                if (this.pessoa["representante"] === undefined) {
                    this.pessoa["representante"] = {};
                }
                if (this.pessoa["representante"].telefone === undefined) {
                    this.pessoa["representante"].telefone = {};
                }
                
                if (this.pessoa["representante"].nome !== undefined) {
                    this.nome = this.pessoa["representante"].nome;
                }
                if (this.pessoa["representante"].cpf !== undefined) {
                    this.cpf = this.pessoa["representante"].cpf;
                }
                if (this.pessoa["representante"]["tipo-representacao"] !== undefined) {
                    this.tipoRepresentacao = this.pessoa["representante"]["tipo-representacao"];
                }
                if (this.pessoa["representante"].email !== undefined) {
                    this.email = this.pessoa["representante"].email;
                }
                if (this.pessoa["representante"]["data-nascimento"] !== undefined) {
                    this.dataNascimento = this.pessoa["representante"]["data-nascimento"];
                }

                if (this.pessoa.representante !== undefined && this.pessoa.representante.telefone !== undefined && this.pessoa.representante.telefone != null &&
                    (this.pessoa.representante.telefone.tipo !== undefined && this.pessoa.representante.telefone.tipo != null && this.pessoa.representante.telefone.tipo.toString() == "1") &&
                    (this.pessoa.representante.telefone.ddd !== undefined && this.pessoa.representante.telefone.ddd != null) &&
                    (this.pessoa.representante.telefone.numero !== undefined && this.pessoa.representante.telefone.numero != null)
                ) {
                    this.telefone = (this.pessoa.representante.telefone.ddd + this.pessoa.representante.telefone.numero.replace("-", "")).toString();
                }
                    
            },
            next: async function (event) {
                event.preventDefault();

                this.rules = {
                    required: value => !!value || 'Campo obrigatório.',
                    onlyalpha: value => {
                        const pattern = /^[A-Za-zà-úá-úâ-ûä-üã-õçñÀ-ÙÁ-ÚÂ-ÛÄ-ÜÃ-ÕÇÑ ]+$/
                        return pattern.test(value) || 'Preencha somente com letras.'
                    },
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'E-mail inválido.'
                    },
                    validdate: v => {
                        if (v == null) return true;

                        var s = v.split('/');
                        var d = new Date(+s[2], s[1] - 1, +s[0]);
                        var ok = false;
                        if (Object.prototype.toString.call(d) === "[object Date]") {
                            if (!isNaN(d.getTime()) && d.getDate() == s[0] &&
                                d.getMonth() == (s[1] - 1)) {
                                ok = true;
                                //return true;
                            }
                        }
                        if (ok && (parseInt(+s[2]) < 1000 || d.getFullYear() < 1000))
                            ok = false;

                        if (!ok)
                            return "Data inválida";

                    },
                    celular: value => {
                        if (value !== undefined && value !== null && value != "") {
                            value = value.replace("-", "");
                            value = value.replace("(", "");
                            value = value.replace(")", "");
                            value = value.replace(" ", "");
                            if (value.length < 11 || isNaN(value)) {
                                return "Número inválido";
                            }
                        }
                        else {
                            return "Número inválido";
                        }
                    },
                    validcpf: ii => {
                        if (ii == null) return true;

                        var c = ii.replace(/[^\d]/g, "");
                        if (c.length != 11)
                            return "CPF inválido";

                        if (c == "00000000000" ||
                            c == "11111111111" ||
                            c == "22222222222" ||
                            c == "33333333333" ||
                            c == "44444444444" ||
                            c == "55555555555" ||
                            c == "66666666666" ||
                            c == "77777777777" ||
                            c == "88888888888" ||
                            c == "99999999999")
                            return "CPF inválido";

                        var r;
                        var s = 0;

                        for (i = 1; i <= 9; i++)
                            s = s + parseInt(c[i - 1]) * (11 - i);

                        r = (s * 10) % 11;

                        if ((r == 10) || (r == 11))
                            r = 0;

                        if (r != parseInt(c[9]))
                            return "CPF inválido";

                        s = 0;

                        for (var i = 1; i <= 10; i++)
                            s = s + parseInt(c[i - 1]) * (12 - i);

                        r = (s * 10) % 11;

                        if ((r == 10) || (r == 11))
                            r = 0;

                        if (r != parseInt(c[10]))
                            return "CPF inválido";

                        return true;
                    },
                };


                this.$nextTick(() => {
                    if (this.$refs.form.validate()) {


                        var v = this.telefone;
                        if (v !== undefined && v !== null && v != "") {
                            v = v.replace("-", "");
                            v = v.replace(" ", "");
                            this.pessoa["representante"].telefone = {};
                            this.pessoa["representante"].telefone.ddi = "55";
                            this.pessoa["representante"].telefone.ddd = v.substring(0, 2);
                            this.pessoa["representante"].telefone.numero = v.substring(2, 11);
                            this.pessoa["representante"].telefone.tipo = "1";
                        }

                        this.pessoa["representante"].nome = this.nome.capitalize();
                        this.pessoa["representante"].cpf = this.cpf.capitalize();
                        this.pessoa["representante"]["tipo-representacao"] = this.tipoRepresentacao;
                        this.pessoa["representante"].email = this.email;
                        this.pessoa["representante"]["data-nascimento"] = this.dataNascimento;

                        this.hasErrors = false;
                        this.$emit('next');

                    }
                })
            },
            back: function () {
                this.$emit('back');
            },
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

