import { render, staticRenderFns } from "./s24-trabalha-2.vue?vue&type=template&id=635d41dc&scoped=true&"
import script from "./s24-trabalha-2.vue?vue&type=script&lang=js&"
export * from "./s24-trabalha-2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "635d41dc",
  null
  
)

export default component.exports